import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { reducer as formReducer, reducer } from 'redux-form'
import { appReducer } from './app-reducer'
import { eClaimReducer } from './e-claim-reducer'
import { enrollmentReducer } from './enrollment-reducer'

const reducers = combineReducers({
  app: appReducer,
  form: formReducer,
  eClaim: eClaimReducer,
  enrollment: enrollmentReducer,
})

export type RootState = ReturnType<typeof reducers>

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
export default store
