import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import { I18nextProvider } from 'react-i18next'
// import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import store from './redux-store'
import i18n from './constant/i18n'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import config from './config'

export const queryCache = new QueryClient({
  defaultOptions: {
    queries: { cacheTime: 5 * 60 * 1000, staleTime: 5 * 60 * 1000 },
  },
})
const history = createBrowserHistory()

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={queryCache}>
      <Provider store={store}>
        <Router history={history}>
          <App id="app-root" />
        </Router>
      </Provider>
      {['development'].includes(config.appMode) && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </I18nextProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
