import { Context, createContext, useCallback, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import queryString from 'query-string'
import { DeepPartial } from 'redux'
import deepmerge from 'deepmerge'
import { isEqual } from 'lodash'
import { useGetEnrollmentPeriod } from '../services/enrollment/enrollment-query'
import dayjs from 'dayjs'
import numeral from 'numeral'
import config from '../config'
import { useGetUser } from '../services/user/user-query'
import { useGetRelativeTypeList } from '../services/relative/relative-query'

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const formatNumber = (value: string) => {
  if (!value) return ''
  return numeral(value).format('0,0')
}

export const normalizeRemoveCommaNoDeciaml = (value: string) => {
  if (value === undefined) return
  if (!value) return ''
  return numeral(value).format('0')
}

export const useRouter = <TQuery extends any = any>() => {
  const params = useParams()
  const location = useLocation()
  // const history = useHistory()
  // const match = useRouteMatch()

  return useMemo(() => {
    return {
      // push: history.push,
      // replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      } as TQuery,
      // match,
      // location,
    }
  }, [params, location])
}

export const setToken = (token: string) => {
  localStorage.setItem('AUTH_TOKEN', token)

  return Promise.resolve(token)
}

export const getToken = () => {
  return localStorage.getItem('AUTH_TOKEN')
}

export const clearToken = () => {
  localStorage.removeItem('AUTH_TOKEN')
}

export const setLanguage = (lang: string) => {
  localStorage.setItem('LANGUAGE', lang)
}

export const getLanguage = () => {
  return localStorage.getItem('LANGUAGE') || 'th'
}

export const setShowModalDownloadInsuranceDetail = (isShow: 'hide' | 'show') => {
  localStorage.setItem('MODAL_DOWNLOAD_INSURANCE_DETAIL', isShow)
}

export const getShowModalDownloadInsuranceDetail = () => {
  return localStorage.getItem('MODAL_DOWNLOAD_INSURANCE_DETAIL')
}

export const setShowModalDownloadHealthCheckupDetail = (isShow: 'hide' | 'show') => {
  localStorage.setItem('MODAL_DOWNLOAD_HEALTH_CHECKUP_DETAIL', isShow)
}

export const getShowModalDownloadHealthCheckupDetail = () => {
  return localStorage.getItem('MODAL_DOWNLOAD_HEALTH_CHECKUP_DETAIL')
}

export const numberWithCommas = (value: number, digit = undefined) => {
  const config = {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  }
  return value ? value.toLocaleString('en-US', config) : value
}

export const withCtx =
  (Context: Context<any>) => (Component: React.ElementType) => (props: any) => {
    const initalState = useMemo(() => {
      return (Context as any)._currentValue[0] || {}
    }, [])
    const [state, setState] = useState(initalState)
    const customSetState = useCallback(
      (v: any) => {
        const newState = deepmerge(state, v)
        if (!isEqual(newState, state)) {
          setState(newState)
        }
      },
      [state],
    )
    const reset = useCallback(() => {
      if (!isEqual(initalState, state)) {
        setState(initalState)
      }
    }, [state, initalState])

    return (
      <Context.Provider value={[state, customSetState, { reset, initialValue: initalState }]}>
        <Component {...props} />
      </Context.Provider>
    )
  }

export const createCtx = <T extends object>(initValue: T) => {
  return createContext<
    [T, (value: DeepPartial<T>) => void, { reset: () => void; initialValue: T }]
  >([
    initValue,
    (value: DeepPartial<T>) => { },
    {
      reset: () => { },
      initialValue: initValue,
    },
  ])
}

export const createFormData = <T extends object>(data: T) => {
  const formData = new FormData()
  Object.entries(data).forEach(([k, v]) => {
    formData.append(k, v)
  })
  return formData
}

export const useCanEditable = (startDate: number, endDate: number) => {
  const currentDate = dayjs()
  const canEdiable = useMemo(() => {
    if (currentDate < dayjs(startDate) || currentDate > dayjs(endDate)) {
      return false
    } else if (currentDate >= dayjs(startDate) && currentDate <= dayjs(endDate)) {
      return true
    } else {
      return false
    }
  }, [currentDate, endDate, startDate])
  return canEdiable
}

export const isScg = config.theme === 'scg' ? true : false

export const useProbation = () => {
  const { data: user } = useGetUser()
  const { probation, probationDate } = user || {}

  const probationDateIsBefore = useMemo(() => {

    if (!probationDate) return true
    if (dayjs(probationDate).isAfter('2023-01-01')) return false

    return true
  }, [probationDate])

  return [probation, probationDateIsBefore]
}

export const useRelativeTypeName = () => {
  const { data: relativeTypeList } = useGetRelativeTypeList()

  const relativeType = useCallback((id: number) => {
    return relativeTypeList?.find(value => {
      return value.value === id
    })?.label || ''
  }, [relativeTypeList])

  return relativeType
}