declare global {
  interface Window {
    _env_: any
  }
}

const wenv = window?._env_ ?? {}
type APP_MODE = 'development' | 'staging' | 'production'

const config = {
  appMode: (wenv.appMode || 'production') as APP_MODE,
  apiHost: wenv.apiHost,
  azureActiveDirectoryAppClientId: wenv.azureActiveDirectoryAppClientId,
  azureActiveDirectoryAppAuthority: wenv.azureActiveDirectoryAppAuthority,
  azureActiveDirectoryAppRedirectUri: wenv.azureActiveDirectoryAppRedirectUri,
  ssoLogin: wenv.ssoLogin,
  theme: wenv.theme,
}

export default config
