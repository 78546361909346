import { CSSProperties, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import iconBanpu from '../../assets/images/base/icon-banpu.png'

import {
  Calendar,
  MaskImage,
  ChartIcon,
  IconPDF,
  IconHistory,
  IconCoinWithBackground,
  IconNews,
  CloseIcon,
  HamburgerIcon,
  LogoutIcon,
  ThaiFlag,
  UsaFlag,
  CloseIcon18,
  TrainIcon,
  IconCoinBlue,
  IconCoinRed,
  IconCoinBlack,
  IconEdit,
  IconDelete,
  IconCoinsGold,
  IconCoinRedWithBg,
  FitnessImage,
  IconAll,
  IconCoin,
  CalendarIcon,
  IconUser,
  IconUserGroup,
  IconTrip,
  IconEnroll,
  IconEnrollBag,
  IconChange,
  ReceiptIcon,
  IdCardIcon,
  IconAdd,
  IconCoinPrimaryWithBg,
  IconEye,
  IconPoints,
  IconNext,
  IconComment,
  IconPin,
  IconPicture,
  IconRadio,
  IconRadioMarked,
  IconBenefit,
  IconIPD,
  IconOPD,
  IconDentalInsurance,
  // IconIdCard,
  IconLocation,
  IconMail,
  IconPhoneNumber,
  IconNotification,
  IconSync,
  IconFamily,
  IconFamilyWhite,
  IconPersonalWhite,
  IconEClaimList,
  BackgroundCover,
  IconBack,
  IconBin,
  IconReload
} from '../../assets/images/images-belive'

export const ImageNameBelive = {
  calendar: Calendar,
  iconCoin: IconCoin,
  calendarIcon: CalendarIcon,
  chartIcon: ChartIcon,
  iconPDF: IconPDF,
  iconEye: IconEye,
  iconHistory: IconHistory,
  iconCoinWithBackground: IconCoinWithBackground,
  iconPin: IconPin,
  iconPicture: IconPicture,
  iconNews: IconNews,
  closeIcon: CloseIcon,
  hamburgerIcon: HamburgerIcon,
  logoutIcon: LogoutIcon,
  thaiFlag: ThaiFlag,
  usaFlag: UsaFlag,
  closeIcon18: CloseIcon18,
  trainIcon: TrainIcon,
  receiptIcon: ReceiptIcon,
  idCardIcon: IdCardIcon,
  iconUser: IconUser,
  iconUserGroup: IconUserGroup,
  iconTrip: IconTrip,
  iconEnroll: IconEnroll,
  iconCoinBlue: IconCoinBlue,
  iconCoinRed: IconCoinRed,
  iconCoinBlack: IconCoinBlack,
  iconEdit: IconEdit,
  iconDelete: IconDelete,
  iconAdd: IconAdd,
  iconEnrollBag: IconEnrollBag,
  iconChange: IconChange,
  iconCoinsGold: IconCoinsGold,
  iconCoinRedWithBg: IconCoinRedWithBg,
  iconCoinPrimaryWithBg: IconCoinPrimaryWithBg,
  fitnessImage: FitnessImage,
  iconAll: IconAll,
  iconUserWhite: IconUser,
  iconUserGroupWhite: IconUserGroup,
  iconTripWhite: IconTrip,
  iconEnrollWhite: IconEnroll,
  iconChangeWhite: IconChange,
  iconCoinWhite: IconCoin,
  iconPoints: IconPoints,
  iconNext: IconNext,
  iconComment: IconComment,
  iconRadio: IconRadio,
  iconRadioMarked: IconRadioMarked,
  iconBenefit: IconBenefit,
  iconIPD: IconIPD,
  iconOPD: IconOPD,
  iconDentalInsurance: IconDentalInsurance,
  IconLocation,
  IconMail,
  IconPhoneNumber,
  IconNotification,
  IconSync,
  IconFamily,
  IconFamilyWhite,
  IconPersonalWhite,
  IconEClaimList,
  BackgroundCover,
  IconBack,
  IconBin,
  IconReload
}
const currentIcon = ImageNameBelive

export type ImageType = typeof currentIcon
export type ImageNameKeys = keyof ImageType

type ImageProps<T extends ImageNameKeys | undefined> =
  | { src: string; fallBack?: string; imageName?: T }
  | { src?: string; fallBack?: string; imageName: T }

const StyleImage = styled.img`
  object-fit: cover;
`

const Image = <T extends ImageNameKeys>(
  props: ImageProps<T> &
    Omit<
      React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
      'src'
    >,
) => {
  const { fallBack, imageName, src, ...rest } = props

  const onError = useCallback((e) => {
    e.target.src = iconBanpu
  }, [])
  const ImageNameCard = useMemo(() => {
    return imageName ? currentIcon[imageName] : src
  }, [imageName, src])

  return <StyleImage onError={onError} src={ImageNameCard} {...rest} />
}

export default Image
