import { Route, Switch, Redirect } from 'react-router'

import * as paths from '../constant/path'
import { ProtectedRoute } from './protected'
import { Wiki } from '../pages/Wiki'
import { useMemo } from 'react'
import PageNotFound from '../pages/404'
import Login from '../pages/Login'
import AuthCallback from '../pages/AuthCallback'
import LoginADAuto from '../pages/Login/LoginADAuto'

import { useGetUser } from '../services/user/user-query'
import { LoadingOutlined } from '@ant-design/icons'
import config from '../config'
import Home from '../pages/Home'
import Disble from '../pages/Disable'
import dayjs from 'dayjs'

export const Routes = () => {
  // const { data, isLoading } = useGetUser()

  // const isAuthorized = useMemo(() => {
  //   return !!data
  // }, [data])

  return <Route path="/" component={Disble} />

  // return isLoading ? (
  //   <LoadingOutlined spin={isLoading} />
  // ) : (
  //   <Switch>
  //     {!isAuthorized ? (
  //       <Route path={paths.authCallback()} component={AuthCallback} />
  //     ) : (
  //       <Redirect from={paths.authCallback()} to="/" />
  //     )}
  //     {!isAuthorized ? (
  //       <Route path={paths.authAzureAd()} component={LoginADAuto} />
  //     ) : (
  //       <Redirect from={paths.authAzureAd()} to="/" />
  //     )}

  //     {!isAuthorized ? (
  //       <Route path={paths.login()} component={Login} />
  //     ) : (
  //       <Redirect from={paths.login()} to="/" />
  //     )}

  //     {['development'].includes(config.appMode) && <Route path="/wiki" component={Wiki} />}
  //     <Route path={paths.notFound()} component={PageNotFound} />

  //     {!isAuthorized ? <Redirect to={paths.login()} /> : <ProtectedRoute />}
  //   </Switch>
  // )
}
