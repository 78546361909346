import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export type EClaimInf = {
  claimInfo: {
    id?: number
    name: string
    exchangeCoin: number
    userClaimPrice: number
    optionalFileUrl: string
    receiptFile: string
    userRemark: string
    relativeId: string
    allpayRef?: string
    allpayReason?: string
    allpayRemark?: string
  }
}

export const initState = {
  claimInfo: {
    name: '',
    exchangeCoin: 0,
    userClaimPrice: 0,
    optionalFileUrl: '',
    receiptFile: '',
    userRemark: '',
    relativeId: '',
  },
}

const eClaimSlice = createSlice({
  name: 'eClaim',
  initialState: initState,
  reducers: {
    setClaimInfo: (state, { payload }: PayloadWithType<EClaimInf['claimInfo']>) => {
      state.claimInfo = payload
    },
    clearClaimInfo: (state) => {
      state.claimInfo = initState.claimInfo
    },
  },
})

export const eClaimReducer = eClaimSlice.reducer

export const { setClaimInfo, clearClaimInfo } = eClaimSlice.actions
