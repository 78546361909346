import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { clearToken } from '../../utils/helper'
import {
  Relative,
  RelativeList,
  CreateRelativeParams,
  UpdateRelativeParams,
  RelativeTypeList
} from './relative-typed'
import { queryCache } from '../../index'
import { ContentType } from '../../utils/api/tools'

export const EDIT_USER_FORM_NAME = 'EditUserFormName'

export const APP = '/app'
export const RELATIVE_URL = `${APP}/relative/`
export const RELATIVE_TYPE_URL = `${RELATIVE_URL}relative-type-list`

export const useGetRelative = () => {
  return useQuery<RelativeList | undefined, Error>(
    RELATIVE_URL,
    () => {
      return api.belive.get<RelativeList>(RELATIVE_URL)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  )
}

export const useGetRelativeById = (id: number) => {
  return useQuery<Relative>(
    [`${RELATIVE_URL}-ID`, id],
    () => {
      return api.belive.get<Relative>(`${RELATIVE_URL}${id}`)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!id,
    },
  )
}

export const useGetRelativeTypeList = () => {
  return useQuery(
    RELATIVE_TYPE_URL,
    async () => {
      const response = await api.belive.get<RelativeTypeList>(RELATIVE_TYPE_URL)
      const optionRelative: {
        label: string
        value: string | number
        disabled?: boolean
      }[] = []

      response.map((relative) => {
        const { name, id } = relative
        optionRelative.push({ label: `${name}`, value: id })
      })

      return optionRelative
    }
  )
}

export const useDeleteRelativeById = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [RELATIVE_URL],
    (id: number) => {
      return api.belive.delete(`${RELATIVE_URL}${id}`)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([RELATIVE_URL])
      },
    }
  )
}

export const useUpdateRelativeById = () => {
  const queryClient = useQueryClient()
  return useMutation(
    RELATIVE_URL,
    (relative: UpdateRelativeParams) => {
      return api.belive.post<Relative>(
        RELATIVE_URL,
        { ...relative },
        { contentType: ContentType.JSON }
      )
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries([RELATIVE_URL])
        queryClient.invalidateQueries([`${RELATIVE_URL}-ID`, response.id])
      }
    }
  )
}

export const useCreateRelative = () => {
  return useMutation(
    RELATIVE_URL,
    (relative: CreateRelativeParams) => {
      return api.belive.post<Relative>(
        RELATIVE_URL,
        { ...relative },
        { contentType: ContentType.JSON }
      )
    },
  )
}