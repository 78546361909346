export const enrollment = {
  modalPrivacyOne: {
    th: {
      title: 'จากการเลือกปรับเปลี่ยนสวัสดิการแผนประกันของตนเอง ข้าพเจ้าขอยืนยันและตกลงตามข้อตกลงดังต่อไปนี้',
      one: 'ข้าพเจ้าได้อ่าน ทำความเข้าใจ และยอมรับในข้อตกลงและเงื่อนไข ขั้นตอนการดำเนินการตลอดจนถึงรายละเอียดของสวัสดิการแผนประกันของตนเองที่ทางบริษัทฯ ได้จัดเตรียมให้และสื่อสารกับข้าพเจ้า โดยข้าพเจ้ามีสิทธิที่จะเข้าร่วมในโปรแกรมสวัสดิการดังกล่าว',
      two: 'โดยการกดปุ่ม “ยืนยัน” ข้าพเจ้ามีความประสงค์ที่จะใช้สวัสดิการที่ข้าพเจ้าได้เลือก สวัสดิการที่เลือกนั้นอยู่ในการใช้ดุลยพินิจ การพิจารณา และการตัดสินใจเลือกของข้าพเจ้าเอง',
      three: 'ข้าพเจ้ายินดีรับผิดชอบค่าใช้จ่ายส่วนเกินที่เกิดขึ้นจากการเลือกแผนประกันที่มีมูลค่าสูงกว่าแผนดั้งเดิม (default) ตามสิทธิ์ และยอมให้หักเงินค่าใช้จ่ายนี้ผ่านระบบเงินเดือน (payroll)',
      four: 'เมื่อการลงทะเบียนเสร็จสิ้นและทางบริษัทฯ ได้ยอมรับการลงทะเบียนดังกล่าว ข้าพเจ้ายอมรับและตกลงว่าข้าพเจ้าไม่สามารถทำการเปลี่ยนแปลงใด ๆ ในสวัสดิการที่ข้าพเจ้าเลือก จนกระทั่งรอบระยะเวลาการเลือกและลงทะเบียนรอบใหม่ตามที่บริษัทฯ กำหนด',
      five: 'หากข้าพเจ้าไม่ได้ทำการเลือกสวัสดิการในช่วงระยะเวลาการลงทะเบียนที่บริษัทกำหนด ข้าพเจ้ารับทราบและยอมรับในข้อกำหนดของสิทธิประโยชน์ประกันกลุ่มตามแผนดั้งเดิม (default) ตามนโยบายที่บริษัทจัดให้',
      six: 'ข้าพเจ้าเข้าใจและยอมรับว่าการเลือกสวัสดิการบางรายการจะถูกบันทึกเป็นรายได้ เพื่อนำไปคำนวณภาษีเงินได้ส่วนบุคคลในงวดเงินเดือน (payroll) ตามอัตราภาษีที่สรรพากรกำหนด',
      seven: 'ข้าพเจ้าเข้าใจและยอมรับว่าการมีสิทธิในสวัสดิการต่างๆ ของบริษัทจะสิ้นสุดลง ณ วันที่สิ้นสุดสภาพการจ้างงาน',
      eight: 'สิทธิประโยชน์ทางด้านประกันนั้นจะเป็นไปตามเงื่อนไขและข้อตกลงของบริษัทประกัน ในกรณีที่มีข้อขัดแย้งระหว่างเงื่อนไขและข้อตกลงของประกัน ให้อ้างอิงเงื่อนไขและข้อตกลงของบริษัทประกันเป็นหลัก',
      footer: 'ในการกดปุ่ม “ยืนยัน” ข้าพเจ้ายืนยันว่าข้าพเจ้าได้อ่าน ทำความเข้าใจ และยอมรับในข้อตกลงและเงื่อนไขตามประกาศข้างต้น'
    },
    en: {
      title: 'By selecting to adjust insurance plan benefits, I confirm and agree to the terms and conditions as follows:',
      one: 'I have read, understood and agreed to the terms and conditions, procedures, and details of my insurance plan benefits as the Company provided and communicated. I am eligible to participate in such benefits program.',
      two: 'By clicking “Confirm,” I would like to use the benefits plan I selected under my discretion, consideration and decision.',
      three: 'I agree to be responsible for excess costs incurred by selecting an insurance plan more expensive than the eligible default plan and to allow payroll deductions for such excess.',
      four: 'Once the registration has been completed and the Company has agreed with it, I accept and agree that I cannot make any changes to the benefits I selected until the next selection and registration as specified by the Company.',
      five: 'If I have not made a benefits selection during the registration period set by the Company, I acknowledge and accept the Terms of the default Group Insurance Benefits according to the policy provided by the Company.',
      six: 'I understand and accept that a selection of some benefits options will be recorded as income for personal income tax in the payroll as per the tax rate set by the Revenue Department.',
      seven: 'I understand and accept that the Company welfare benefits entitlement will end on the date of termination of employment.',
      eight: 'Insurance benefits will be in accordance with the terms and conditions of the Insurance Company. In the event of any conflict between the terms and conditions of the insurance, we will mainly refer to the terms and conditions of the Insurance Company.',
      footer: 'By clicking “Confirm,” I confirm that I have read, understood and agreed the terms and conditions as announced above.'
    }
  },
  modalPrivacyTwo: {
    th: 'ข้าพเจ้าเข้าใจและตกลงว่าหากข้าพเจ้าได้เลือกสวัสดิการที่เกินกว่าจำนวนที่บริษัทจัดสรรให้ ซึ่งข้าพเจ้าจะต้องชำระเงินแก่ผู้ขายสินค้าและ/หรือให้บริการเพิ่มเติม ข้าพเจ้ายินยอมที่จะชำระเงิน โดยยินยอมให้บริษัทฯ หักค่าจ้างของข้าพเจ้าในเดือนนั้น เพื่อนำไปชำระให้แก่ผู้ขายสินค้าและ/หรือให้บริการในนามของข้าพเจ้าได้',
    en: 'I understand and agree that if I select the benefits plan more expensive than the one provided by the Company which I have to make payment for additional fees to a product and/or service Provider, I consent to allow the Company to deduct such expenses from my salary in the month of event occurrence in order to be paid to the  product and/or service Provider on my behalf.'
  }
}

export const healthCheckup = {
  modalPrivacyOne: {
    th: {
      title: 'จากการเลือกโรงพยาบาลในการตรวจสุขภาพประจำปีของตนเอง ข้าพเจ้าขอยืนยันและตกลงตามข้อตกลงดังต่อไปนี้',
      one: 'ข้าพเจ้าได้อ่าน ทำความเข้าใจ และยอมรับในข้อตกลงและเงื่อนไข ขั้นตอนการดำเนินการตลอดจนถึงรายละเอียดของการเลือกโรงพยาบาลของตนเองตามที่ทางบริษัทฯ ได้จัดเตรียมให้และสื่อสารกับข้าพเจ้า โดยข้าพเจ้ามีสิทธิที่จะเข้าร่วมในโปรแกรมสวัสดิการดังกล่าว',
      two: 'โดยการกดปุ่ม “ยืนยัน” ข้าพเจ้ามีความประสงค์ที่จะไปใช้สิทธิ์ตามโรงพยาบาลที่ข้าพเจ้าได้เลือก ซึ่งการเลือกนั้นอยู่ในการใช้ดุลยพินิจ การพิจารณา และการตัดสินใจเลือกของข้าพเจ้าเอง',
      three: 'ข้าพเจ้ายินดีรับผิดชอบค่าใช้จ่ายส่วนเกิน ที่เกิดขึ้นจากการตรวจสุขภาพในรายการตรวจที่นอกเหนือจากสิทธิ์ที่ข้าพเจ้าได้ โดยชำระค่าจ่ายโดยตรงกับทางโรงพยาบาล แต่หากทางโรงพยาบาลยังมีการเรียกเก็บค่าใช้จ่ายส่วนเกินกับทางบริษัทฯ ข้าพเจ้ายินยอมให้หักเงินค่าใช้จ่ายนี้ผ่านระบบเงินเดือน (payroll)',
      four: 'เมื่อการลงทะเบียนเสร็จสิ้นและทางบริษัทฯ ได้ยอมรับการลงทะเบียนดังกล่าว ข้าพเจ้ายอมรับและตกลงว่าข้าพเจ้าไม่สามารถทำการเปลี่ยนแปลงใด ๆ ในสวัสดิการที่ข้าพเจ้าเลือก จนกระทั่งรอบระยะเวลาการเลือกและลงทะเบียนรอบใหม่ตามที่บริษัทฯ กำหนด',
      five: 'หากข้าพเจ้าไม่ได้ทำการเลือกโรงพยาบาลในช่วงระยะเวลาที่บริษัทฯ กำหนด ข้าพเจ้ารับทราบและยอมรับในข้อกำหนดของสิทธิในการตรวจสุขภาพประจำปี ตามแผนดั้งเดิม (default) ตามนโยบายที่บริษัทฯ จัดให้',
      six: 'ข้าพเจ้าเข้าใจและยอมรับว่าการมีสิทธิในสวัสดิการต่างๆ ของบริษัทจะสิ้นสุดลง ณ วันที่สิ้นสุดสภาพการจ้างงาน',
      seven: 'สิทธิประโยชน์การตรวจสุขภาพนั้นจะเป็นไปตามเงื่อนไขและข้อตกลงของบริษัทฯ เท่านั้น',
      footer: 'ในการกดปุ่ม “ยืนยัน” ข้าพเจ้ายืนยันว่าข้าพเจ้าได้อ่าน ทำความเข้าใจ และยอมรับในข้อตกลงและเงื่อนไขตามประกาศข้างต้น'
    },
    en: {
      title: 'By selecting a hospital for my annual health checkup, I confirm and agree to the terms and conditions as follows:',
      one: 'I have read, understood and agreed to the terms and conditions, procedures, and details of selecting a hospital as the Company provided and communicated to me. I am eligible to participate in such benefits program.',
      two: 'By clicking “Confirm,” I would like to exercise the right to have a health checkup in the hospital I selected under my discretion, consideration and decision.',
      three: 'I agree to be responsible for excess costs incurred by health checkup lists beyond my eligibility and allow payroll deductions for such excess.',
      four: 'Once the registration has been completed and the Company has agreed with it, I accept and agree that I cannot make any changes to the benefits I selected until the next selection and registration as specified by the Company.',
      five: 'If I have not made a hospital selection during the registration period set by the Company, I acknowledge and accept the Terms of the default Annual Health Checkup Benefit according to the policy provided by the Company.',
      six: 'I understand and accept that the Company welfare benefits entitlement will end on the date of termination of employment.',
      seven: 'Health Checkup Benefits will be in accordance with the terms and conditions of the Company only.',
      footer: 'By clicking “Confirm,” I confirm that I have read, understood and agreed with the terms and conditions as announced above.'
    }
  },
}