export const subHeaderTh = 'กรุณาอ่านข้อกำหนดและเงื่อนไขเหล่านี้อย่างละเอียดก่อนเข้าใช้เว็บไซต์นี้'
export const subHeaderEn = 'Please read these terms and conditions carefully before using this Website.'

export const webAccessTextTh =
  'เราไม่รับประกันว่าเว็บไซต์หรือเนื้อหาใดๆ ที่มีอยู่ของเราจะพร้อมใช้งานตลอดเวลาหรือไม่มีการหยุดชะงัก การอนุญาตให้เข้าใช้เว็บไซต์ของเราเป็นแบบชั่วคราว เราอาจระงับ ถอน ยกเลิก หรือเปลี่ยนแปลงบางส่วน หรือทั้งหมดของเว็บไซต์ของเราโดยไม่ต้องแจ้งให้ทราบล่วงหน้า เราจะไม่รับผิดชอบใดๆ ต่อคุณ หากเว็บไซต์ของเราไม่สามารถให้บริการได้ตลอดเวลาหรือในช่วงเวลาใด ๆ คุณมีหน้าที่รับผิดชอบในการทำข้อตกลงที่จำเป็นทั้งหมดสำหรับคุณในการเข้าใช้เว็บไซต์ของเรา คุณมีหน้าที่รับผิดชอบในการตรวจสอบให้แน่ใจว่าบุคคลใดก็ตามที่เข้าใช้เว็บไซต์ของเราผ่านทางอินเทอร์เน็ตของคุณทราบถึงข้อกำหนดในการให้บริการและข้อกำหนดและเงื่อนไขอื่นๆ ที่ระบุไว้ข้างต้นและสอดคล้องกับข้อกำหนดเหล่านี้'
export const webAccessTextEn =
  'We do not guarantee that our Website, or any content on it, will always be available or be uninterrupted. Access to our Website is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of the Website without prior notice. We will not be liable to you if for any reason the Website is unavailable at any time or for any period. You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your internet connection are aware of the Terms of Service and other terms and conditions set forth above and that they comply with them.'

export const changeTermTextTh =
  'เราอาจแก้ไขข้อกำหนดเหล่านี้ได้ตลอดเวลาโดยการแก้ไขที่หน้านี้ เราจะใช้วิธีการที่เหมาะสม เช่น การประกาศในสิ่งที่เกี่ยวข้องบนเว็บไซต์ของเราเพื่อแจ้งให้คุณทราบเกี่ยวกับการแก้ไขดังกล่าว อย่างไรก็ตาม เราขอให้คุณตรวจสอบหน้านี้เป็นครั้งคราว เพื่อแจ้งให้ทราบถึงการเปลี่ยนแปลงใดๆ ที่เราทำขึ้น เนื่องจากคุณจะต้องปฏิบัติตามข้อกำหนดในการให้บริการขณะที่คุณใช้งานเว็บไซต์ของเรา หากคุณไม่เห็นด้วยกับการเปลี่ยนแปลงคุณต้องหยุดใช้งานเว็บไซต์'
export const changeTermTextEn =
  'We may revise these Terms at any time by amending this page. We will use reasonable means to notify you of such changes, such as posting a relevant announcement on our Website. However, we encourage you to check this page from time to time to be informed of any changes we make, as you will be subject to the Terms of Service in force when you use our Website. If you disagree with the changes, you must stop using the Website.'

export const lawTextTh =
  'หากคุณเป็นผู้บริโภค ข้อกำหนดการใช้งาน สาระสำคัญ และการพัฒนาเหล่านี้ (และข้อพิพาทหรือสิทธิเรียกร้องใดๆ ที่ไม่อยู่ในสัญญา) จะอยู่ภายใต้กฎหมายอังกฤษ และคุณและเราทั้งสองเห็นพ้องกันว่าศาลอังกฤษและเวลส์จะมีอำนาจที่ศาล อย่างไรก็ตามข้อใดในมาตราข้อ 17 นี้จะทำให้คุณไม่สามารถยื่นคำร้องต่อศาลของประเทศที่คุณพำนักอยู่ภายใต้กฎหมายที่บังคับใช้ในประเทศของคุณ ในสถานการณ์ที่คุณมีสิทธิในการทำเช่นนั้นภายใต้กฎหมายที่บังคับใช้ในท้องถิ่น ถ้าคุณเป็นนักธุรกิจ ข้อกำหนดการใช้งาน สาระสำคัญ และการพัฒนาเหล่านี้ (และข้อพิพาทหรือสิทธิเรียกร้องใดๆ ที่ไม่อยู่ในสัญญา) จะอยู่ภายใต้กฎหมายอังกฤษ เราทั้งสองตกลงที่จะอยู่ภายใต้อำนาจที่ไม่ใช่เฉพาะศาลของอังกฤษและเวลส์เท่านั้น'
export const lawTextEn =
  'If you are a consumer, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by the laws of England. We both agree to the exclusive jurisdiction of the England and Wales courts. However, any article in this Section 17 will prevent you from submitting a claim to the courts of the country of your residence under the laws applicable in your country. In certain circumstances where you have the right to do so under the applicable local laws, if you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by the laws of England. We both agree to the non-exclusive jurisdiction of the England and Wales courts.'
